import { notification } from "antd";
import DELETE_USER from "./deleteUser.graphql";
import { DeleteUser, DeleteUserVariables } from "./types/DeleteUser";
import React, { FC, useCallback } from "react";
import GraphQLConfirmButton, {
  GraphQLConfirmButtonProps,
} from "components/graphQLConfirmButton";

type DeleteRoleButtonBaseProps = GraphQLConfirmButtonProps<
  DeleteUser,
  DeleteUserVariables
>;

interface DeleteRoleButtonProps
  extends Omit<
    DeleteRoleButtonBaseProps,
    "mutation" | "title" | "onCompleted"
  > {
  userId: number;
  userName: string;
}

type Props = DeleteRoleButtonProps;

const DeleteUserButton: FC<Props> = ({
  userId: id,
  userName,
  options: { variables, ...options } = {},
  ...props
}) => {
  const handleCompleted = useCallback<
    NonNullable<
      GraphQLConfirmButtonProps<DeleteUser, DeleteUserVariables>["onCompleted"]
    >
  >(
    ({ errors }) =>
      !errors &&
      notification.success({
        message: "Успех!",
        description: (
          <>
            Пользователь <strong>{userName}</strong> был успешно удалён
          </>
        ),
      }),
    [userName]
  );

  return (
    <GraphQLConfirmButton<DeleteUser, DeleteUserVariables>
      title={
        <>
          Вы уверены что хотите удалить пользователя <strong>{userName}</strong>
          ?
        </>
      }
      mutation={DELETE_USER}
      options={{ variables: { id, ...variables }, ...options }}
      onCompleted={handleCompleted}
      {...props}
    />
  );
};

export default DeleteUserButton;
