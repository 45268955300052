import { Button, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { navigate } from "gatsby";
import idx from "idx";
import React, { FC, useCallback, useMemo } from "react";
import GET_USER_LIST from "./getUserList.graphql";
import {
  GetUserList,
  GetUserList_users_items as User,
  GetUserListVariables,
} from "./types/GetUserList";
import Authorize from "components/authorize";
import DeleteUserButton from "components/deleteUserButton";
import GraphQLTable, {
  creationTime,
  lastModificationTime,
  useColumnSearch,
} from "components/graphQLTable";
import MainLayout from "components/layouts/main";
import routes from "routes";

const UserList: FC = () => {
  const handleEdit = useCallback(
    (id: string) => () => navigate(routes.userEditToPath({ id })),
    []
  );

  const { createColumn: createUserNameColumn } = useColumnSearch<User>(
    "userName",
    { dataSearchIndex: "normalizedUserName", useDefaultNormalizer: true }
  );

  const { createColumn: createEmailAddressColumn } = useColumnSearch<User>(
    "emailAddress",
    { dataSearchIndex: "normalizedEmailAddress", useDefaultNormalizer: true }
  );

  const columns = useMemo<Array<ColumnProps<User>>>(
    () => [
      { dataIndex: "id", title: "#", sorter: true },
      createUserNameColumn({
        title: "Имя пользователя",
        sorter: true,
      }),
      createEmailAddressColumn({ title: "Электронная почта", sorter: true }),
      {
        key: "roleNames",
        render: (_text, { roleNames }) => {
          if (!roleNames) {
            return null;
          }

          return (
            <>
              {roleNames.map((roleName, index) => (
                <Tag key={`roleName-${roleName}-${index}`} color="blue">
                  {roleName}
                </Tag>
              ))}
            </>
          );
        },
        title: "Роли",
      },
      creationTime,
      lastModificationTime,
      {
        key: "actions",
        width: "180px",
        render: (_text, { id, name }) => (
          <>
            <Button
              icon="edit"
              className="mr-1"
              size="small"
              type="primary"
              ghost={true}
              onClick={handleEdit(id)}
            >
              Ред.
            </Button>
            <DeleteUserButton
              userId={parseInt(id, 10)}
              userName={name}
              options={{
                refetchQueries: [`GetUserList`],
              }}
            />
          </>
        ),
      },
    ],
    [createEmailAddressColumn, createUserNameColumn, handleEdit]
  );

  const handleNewUserClick = useCallback(() => navigate(routes.userCreate), []);

  const getDataSource = useCallback(
    (data?: GetUserList) => idx(data, x => x.users),
    []
  );

  return (
    <Authorize>
      <MainLayout
        title="Пользователи"
        extra={
          <Button icon="plus" type="primary" onClick={handleNewUserClick}>
            Новый пользователь
          </Button>
        }
      >
        <GraphQLTable<User, GetUserList, GetUserListVariables>
          columns={columns}
          query={GET_USER_LIST}
          getDataSource={getDataSource}
        />
      </MainLayout>
    </Authorize>
  );
};

export default UserList;
